import { inject, Injectable } from '@angular/core';
import { Auth, authState, sendPasswordResetEmail, signInWithEmailAndPassword, signOut, User } from '@angular/fire/auth';
import { doc, docData, Firestore } from '@angular/fire/firestore';
import { BehaviorSubject, map, Observable, of, switchMap } from 'rxjs';

@Injectable({ providedIn: 'root' })
export class AuthService {
  private auth: Auth = inject(Auth);
  private authState$ = authState(this.auth);
  private firestore: Firestore = inject(Firestore);
  private userSubject = new BehaviorSubject<any>(null);

  private orgCode!: string | null;
  constructor() {
    this.authState$
      .pipe(
        switchMap((user: User | null) => {
          console.log('[AuthService] authState - user:', user);
          if (!user) {
            return of(null);
          }
          const billerUserBasePath = `biller-users/${user.uid}`;
          // console.log('[AuthService] authState - path:', billerUserBasePath);
          const docRef = doc(this.firestore, billerUserBasePath);
          return docData<any>(docRef, { idField: 'id' }).pipe(
            map((userData) => ({ ...userData, metadata: user.metadata }))
          );
        })
      )
      .subscribe((billerUser) => {
        this.orgCode = billerUser?.orgCode;
        this.userSubject.next(billerUser);
      });
  }

  getOrgCode(): string | null {
    return this.orgCode;
  }

  getLoggedInUser(): Observable<any> {
    return this.userSubject.asObservable();
  }

  resetPassword(email: string): Promise<void> {
    console.log(email);
    return sendPasswordResetEmail(this.auth, email);
  }

  login(email: string, password: string): Promise<any> {
    console.log(email);
    return signInWithEmailAndPassword(this.auth, email, password);
  }

  logout(): void {
    signOut(this.auth);
  }
}
